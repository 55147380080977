import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"

const EducationalPrograms = () => {
  const data = useStaticQuery(graphql`
    query {
      composite: file(relativePath: { eq: "visit-us/educational-programs/composite.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      bsaEmblem: file(relativePath: { eq: "visit-us/educational-programs/bsa-emblem.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)
  return (
    <Layout>
      <SEO title="Educational Programs" />
      <br />
      <h1>Educational Programs</h1>
      <div><p><strong>Limited parking up to 3 cars/vans</strong></p>
        <p>The following programs can be shared with any group or individuals of all ages (schools, clubs, camps, and neighbors). We are open to customizing program to fit you and your group best interests. This is up to the leader, teacher or parent to use their education and Baxter Barn's.</p>
        <div class="row">
          <div className="col-sm-7">
            <ul>
              <li>Learn about stream conversation in the Snoqualmie Watershed</li>
              <li>Stream water testing – send to King County water scientist</li>
              <li>Restore a stream or wetland</li>
              <li>See fry in the spring and salmon spawning in the fall</li>
              <li>Learn the benefits of conserving water (The Baxter Barn alone saves 300 gallons a month by using rainwater)</li>
              <li>Learn about native plants</li>
              <li>Plant a native garden</li>
              <li>Research and remove invasive plants</li>
              <li>Learn how to build a fungi garden</li>
              <li>Understand composting</li>
              <li>Explore native plants and wildlife</li>
              <li>See native and farm animals</li>
              <li>Explore the use of mason bees and building and placement of boxes</li>
              <li>Learn bird boxes building and placement and about raptor nests</li>
              <li>Observe habitat features (woody debris, rock pile, and perches)</li>
              <li>Learn about raising farm animals (A fun springtime program when chicks are hatching)</li>
              <li>Visit the Historic Baxter Barn</li>
              <li>Learn about the original Fall City settlers- The Baxters</li>
              <li>Find out about our outreach Programs</li>
              <li>Create a brochure or video to teach others about conservation</li>
              <li>Start a campaign at your school to increase conservation awareness</li>
            </ul>
          </div>
          <div className="col-sm-3">
            <Img alt="Educational programs at Baxter Barn" fixed={data.composite.childImageSharp.fixed} />
          </div>
        </div>
        <h4 id="baxter-barn-boy-scout-merits-badges" className="mt-3" style={{ position: `relative` }}>
          <a href="#baxter-barn-boy-scout-merits-badges" aria-label="baxter barn boy scout merits badges permalink" class="anchor before">
            <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg>
          </a>
          Baxter Barn Boy Scout Merits Badges
        </h4>
        <div className="row">
          <div className="col-sm-9">
            <ul>
              <li><strong>Animal Science</strong> (#35854)</li>
              <li><strong>Environmental Science</strong> (#35892)</li>
              <li><strong>Fish and Wildlife Management</strong> (#35898)</li>
              <li><strong>Gardening</strong> (#35902)</li>
              <li><strong>Nature</strong> (#35922)</li>
              <li><strong>Plant Science</strong> (#35932)</li>
              <li><strong>Soil and Water Conservation</strong> (#35952)</li>
              <li><strong>Farm Mechanics</strong> (#35894)</li>
            </ul>
          </div>
          <div className="col-sm-3">
            <Img alt="Boy Scouts of America emblem" fixed={data.bsaEmblem.childImageSharp.fixed} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EducationalPrograms
